$input-height: 32px;

.label {
    font-weight: 600;
    font-size: .9rem;
    margin-bottom: 5px;

    &:not(.is-inline) {
        display: block;
    }
}

.input {
    line-height: $input-height;
    padding: 0 10px;
    box-sizing: border-box;
    border-radius: 3px;

    font-size: 0.8rem;

    background-color: black;
    border: 1px solid rgba(white, .15);
    transition: border .2s ease;

    &.is-full {
        width: 100%;
    }

    &:not(.inline) {
        display: block;
        min-width: 300px;
    }

    &.inline {
        display: inline-block;
    }

    &:hover {
        border-color: $scheme_color_red_darker;
    }

    &:focus {
        border-color: $scheme_color_red;
    }
}

input.input, select.input {
    height: $input-height;
}

input.input {

}

select.input, textarea.input {
    min-width: 427px !important;
}

textarea.input {
    min-height: 100px;
}

textarea.input {
    color: $scheme_color_body_text;
    line-height: normal;
    padding: 10px;
}

.input {
    &.is-success {

    }

    &.is-warning {

    }

    &.is-danger {

    }
}