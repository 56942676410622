#personal_messages {
    .pm-item {
        background-color: $scheme_color_dark_lighter;
        border-radius: 3px;
        box-shadow: $theme_box_shadow;
        margin: 15px;
        .poster {
            padding: 20px;
            background-color: lighten($scheme_color_dark_lighter, 3);
            border-radius: 3px 3px 0 0;
            .avatar {
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }

            li {
                list-style: none;
            }

            .name {
                font-size: 1rem;
            }

            .membergroup {
                margin-top: 5px;
                font-size: 12px;
                color: $scheme_color_body_text_darker;
            }

            .date {
                color: $scheme_color_body_text;
            }
        }

        .postarea {
            padding: 20px;
        }

        &::last-child {
            margin-bottom: 0px;
        }
    }
}