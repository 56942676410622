$page-max-width: 1300px;

// Text
$scheme_color_body_text: darken(white, 15);
$scheme_color_body_text_darker: darken($scheme_color_body_text, 10);

$scheme_color_dark_light: #202020;
$scheme_color_dark_lighter: lighten($scheme_color_dark_light, 5);
$scheme_color_red: #b21622;
$scheme_color_red_darker: darken($scheme_color_red, 10);
$scheme_color_hover_elm: #2F2E2E;
$scheme_color_primary: #D3B126;

$theme_box_shadow: 0 2px 20px rgba(black, .5);

.is-spaced {
    margin: 15px 0;
    &.is-spaced-large {
        margin: 40px 0;
    }

    &.is-spaced-top {
        margin-bottom: 0;
    }
    &.is-spaced-bottom {
        margin-top: 0;
    }
}

.is-block {
    display: block;
    &.is-full {
        width: 100%;
    }
}

.is-containing-box {
    background-color: $scheme_color_dark_light;
    box-shadow: $theme_box_shadow;
    padding: 30px;
    border-radius: 3px;

    &.has-margin {
        margin-top: 30px;
    }

    &.has-margin-bottom {
        margin-bottom: 30px;
    }

    &.is-spaceless {
        padding: 0;
    }
}

@mixin breakpoint($point) {
    @if $point == desktop {
        @media (max-width: 70em) { @content ; }
    }
    @else if $point == laptop {
        @media (max-width: 64em) { @content ; }
    }
    @else if $point == tablet {
        @media (max-width: 50em) { @content ; }
    }
    @else if $point == phablet {
        @media (max-width: 37.5em)  { @content ; }
    }
    @else if $point == mobileonly {
        @media (max-width: 37.5em)  { @content ; }

    }
}

$sizes: (
    "xsmall": .5em,
    "small": .75em,
    "base": 1em,
    "medium": 1.25em,
    "large": 1.5em,
    "huge": 1.75em,
    "big": 2em
);

$directions: (
    "t": top, "b": bottom, "l": left, "r": right
);

@each $dir, $name in $directions {
    @each $key, $size in $sizes {
        .m#{$dir}-#{$key} {
            margin-#{$name}: $size;
        }

        .m#{$dir}-#{$key}-important {
            margin-#{$name}: $size !important;
        }
    }
}