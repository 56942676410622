.error, .alert {
    padding: 20px;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 0 2px 20px rgba(black, .8);
    margin: 10px 0;
    text-align: left !important;
    &.no-margin {
        margin: 0;
    }

    i {
        margin-right: 10px;
    }

    &.is-warning {
        background-color: #ffdd57;
        color: rgba(0, 0, 0, 0.7);
    }

    &.is-info {
        background-color: hsl(204, 76%, 48%);
        color: white;
    }

    &.is-spaced {
        margin-bottom: 2rem;
    }

    &.is-shadowless {
        box-shadow: none;
    }

    &.is-smaller {
        padding: 15px;
    }

    &.has-message {
        display: flex;
        align-items: center;
        i {
            margin-right: 20px;
        }

        .title {
            font-size: 0.9rem;
            margin-bottom: 5px;

        }
        .text {
            font-weight: normal;
        }
    }
}