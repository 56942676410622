.pagelinks {
    display: inline-block;
    line-height: 2.3rem;
    margin-top: 20px;
    padding: 0 20px;
    border-radius: 3px;

    background-color: $scheme_color_dark_light;
    border: 1px solid lighten($scheme_color_dark_light, 10);
    box-sizing: border-box;

    &.is-spaceless {
        margin-top: 0;
    }

    .page {
        display: inline-block;
        padding: 0 10px;

        a {
            display: block;
        }
        &:first-child {
            margin-left: 15px;
        }

        &.active {
            background-color: lighten($scheme_color_dark_light, 10);
        }

        &:hover {
            background-color: lighten($scheme_color_dark_light, 8);
        }
    }
}