@import "vendors/fontello";

@import "core/all-core";
@import "component/all-comonents";
@import "layout/all-layout";
@import "legacy/legacy";

body, html {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-weight: 400;

    background: #171717 url("images/backgrounds/site-background-main.jpg") no-repeat;
    background-position: center 200px;
    background-size: 2100px;

    margin: 0;
}

html {
    position: relative;
    display: block;
    min-height: 100%;
}

body {
    height: 100%;
    padding-bottom: 80px;
}

@import "layout/login";

.container {
    max-width: $page-max-width;
    width: 98%;
    margin: 0 auto;

    &.is-smaller {
        max-width: 800px;
    }
}

.container-flex {
    @extend .container;
    display: flex;
}

.flex {
    display: flex;

    &.is-space-between {
        justify-content: space-between;
    }

    &.is-vcenter {
        align-items: center;
    }

    &.is-space-center {
        justify-content: center;
    }
}

.rbox {
    padding: 10px;
    background-color: #0a0505;
    margin: 10px 5px;
    border: 2px solid lighten(#0a0505, 10);
    border-radius: 5px;
}

.is-inline {
    display: inline-block;
}

.is-padded-more {
    padding: 30px;
}

.is-text-left {
    text-align: left;
}

.is-text-center {
    text-align: center;
}

.is-text-right {
    text-align: right;
}

// TODO: put this somewhere else
.avatar {
    border-radius: 100%;
}

// legacy container styles
.legacy-container {
    padding: 20px;
    background-color: $scheme_color_dark_light;
    box-shadow: $theme_box_shadow;
    border-radius: 3px;
}

