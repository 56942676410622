// Physical list of boards

.stats-board-layout {
    margin-right: 30px;
    .stat {
        display: inline-block;
        &:first-child {
            margin-right: 30px;
        }

        &:not(:first-child) {
            width: 80px;
        }

        .title {
            font-weight: 400;
            font-size: 12px;
            margin-top: 5px;
            color: rgba(white, .3);
        }

        .value {
            font-size: 20px;
            line-height: 22px;
        }
    }

    @include breakpoint(laptop) {
        display: none;
    }
}

.index-list {
    &.is-child-list {
        .list-info {
            padding-top: 10px;
        }
    }
}

.list {
    background-color: $scheme_color_dark_light;
    box-shadow: 0 2px 10px rgba( black, .5);
    margin-bottom: 20px;
    border-radius: 3px;
    &:last-child {
        margin-bottom: 0;
    }

    header {
        padding: 10px 20px 0 20px;
        line-height: 40px;
        margin-bottom: 0;
        justify-content: space-between;
        border-radius: 3px 3px 0 0;
        font-size: 16px;
        font-weight: bold;
        font-style: italic;
        .right {
            text-align: right;
        }

        &.is-collapsed {
            margin-bottom: 0;
            opacity: .4;
            padding-top: 0;
            transition: opacity .2s ease;
            &:hover {
                opacity: 1;
            }
        }

        .collapse {
            i {
                color: rgba(white, .3);
                font-size: 18px;
                transition: color .2s ease;
                &:hover {
                    color: white;
                }
            }
        }
    }

    .list-info {
        padding: 8px 15px;
        padding-top: 0;
        .list-item {
            justify-content: space-between;
            align-items: center;
            padding: 10px;

            &:not(:last-child) {
                margin-bottom: 3px;
                border-bottom: 1px solid rgba(white, .06);
            }

            .info {
                display: flex;
                align-items: center;
                .icon {
                    width: 45px;
                    display: inline-block;
                    img {
                        height: 30px;
                    }
                    margin-right: 10px;
                    @include breakpoint(mobileonly) {
                        display: none;
                    }
                }

                .main {
                    display: inline-block;
                }

                .title {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 16px;

                    padding-bottom: 5px;
                }

                .item-info {
                    color: $scheme_color_body_text;
                    font-size: 12px;
                    padding-bottom: 6px;
                }

                .item-subcats {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 11px;
                    margin-top: 3px;
                    color: rgba($scheme_color_body_text_darker, .7);
                    .child-item {
                        margin-left: 9px;
                        &:first-child {
                            margin-left: 0px;
                        }
                        .new {
                            color: $scheme_color_body_text;
                        }
                        a {
                            color: rgba($scheme_color_body_text_darker, .7);
                            &:hover {
                                color: white;
                            }
                        }
                    }
                }
            }

            .board-extra {

                .last-person {
                    text-align: left;
                    width: 240px;
                    line-height: 1.3rem;

                    .post {
                        margin-top: 0;
                    }
                }

                @include breakpoint(phablet) {
                    display: none;
                }
            }
        }
    }
}

.mark_read {
    padding: 0 !important;
    float: none;
    margin: 20px 0;

    height: 20px;
    display: block;
    text-align: right;
    .floatright {
        float: none;
    }

    ul {
        display: block;
        padding: 0px !important;
        margin: 0 !important;

        li {
            display: block;
            width: 100%;

            a {
                display: block;
                padding: 0 !important;
                margin-left: 0 !important;
            }
        }
    }
}

// Board stats
.board-stats-cont {
    background-color: lighten($scheme_color_dark_light, 3);
    margin: 20px 0;
    box-shadow: 0 2px 10px rgba( black, .5);
    border-radius: 3px;

    header {
        position: relative;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        @include breakpoint(phablet) {
            flex-wrap: wrap;
            .main, .section {
                width: 100%;
            }

            .main {
                padding-bottom: 10px !important;
            }

            .section {
                margin-bottom: 20px;
            }
        }

        a {
            font-size: 12px;
            color: rgba(white, .5) !important;
            transition: color .2s ease;
            margin-right: 10px;
            &:hover {
                color: rgba(white, 1) !important;
            }
        }

        .main {
            padding: 20px 0;

            h3 {
                margin-bottom: 3px;
            }
        }
    }

    .users-online {
        span {
            margin-right: 5px;
            font-style: italic;
            color: rgba(white, .7);
        }
    }

    .board-stats {
        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: $scheme_color_dark_light;

        padding: 30px 20px;
        border-radius: 3px;

        @include breakpoint(phablet) {
            flex-wrap: wrap;
            .item {
                width: 100% !important;
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }
        }

        .item {
            box-sizing: border-box;
            width: 20%;
            .title, .value {
                text-align: center;
            }

            .title {
                color: rgba(white, .5);
                margin-top: 5px;
            }

            .value {
                font-size: 1.5rem;
                font-weight: 100;
            }

            &.large {
                .value {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    padding: 0 30px;
                }
            }
        }
    }
}