.quickmod {
    margin-top: 15px;
    z-index: 100;
    background-color: $scheme_color_dark_light;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 3px;
    box-shadow: $theme_box_shadow;

    .title {
        max-width: 130px;
    }

    > * {
        flex-grow: 1;
    }

    select {
        margin-right: 15px;
        line-height: 30px;
        height: 30px;
        border: 1px solid $scheme_color_dark_lighter;
        padding: 0 20px;
        border-radius: 3px;

        &:disabled {
            opacity: .4;
        }
    }
}

#topic-list {
    &.has-children {
        > header {
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }

    > header {
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
            flex-wrap: wrap;
            h2, > div {
                width: 100%;
                text-align: center;
            }

            h2 {
                margin-bottom: 15px;
            }
        }
    }

    #messageindex {
        background-color: $scheme_color_dark_light;
        border-radius: 3px;
        box-shadow: $theme_box_shadow;

        &.is-empty {
            padding: 0;
        }
    }

    #messageindex header {
        line-height: 50px;
        background-color: lighten($scheme_color_dark_light, 2);
        border-radius: 3px 3px 0 0;
        padding: 0 20px;
        justify-content: space-between;

        // Empty state
        &.is-empty {
            display: block;
            padding: 30px;
            line-height: 2rem;
            h1 {
                text-align: center;
                margin-bottom: 5px;
                padding: 0;
            }

            p {
                padding: 0;
                color: $scheme_color_body_text;
                text-align: center;
            }
        }

        &:not(.is-empty) {
            a {
                transition: color .2s ease;
                color: $scheme_color_body_text;
                &:hover {
                    color: white !important;
                }
                &:visited {
                    color: $scheme_color_body_text;
                }
            }
        }

        .main {
            padding-left: 75px;
            justify-content: space-between;
            box-sizing: border-box;

            @include breakpoint(mobileonly) {
                padding-left: 0 !important;
            }
        }

        .secondary {
            align-items: center;
            justify-content: space-between;

            .replies-views {
                text-align: right;
                margin-right: 50px;

                @include breakpoint(laptop) {
                    display: none;
                }
            }

            .latest-post {
                width: 150px;

                @include breakpoint(phablet) {
                    display: none;
                }
            }

            input {
                display: inline-block;
            }
        }
    }

    .topic {
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;

        &.is-sticky {
            background-color: rgba(black, .19);
        }

        &:not(:last-child) {
            margin-bottom: 3px;
            border-bottom: 1px solid rgba(white, .1);
        }

        .main {
            align-items: center;

            .avatar {
                width: 45px;
                height: 45px;
                img {
                    width: 45px;
                    height: 45px;
                    border-radius: 45px;
                }

                margin-right: 30px;

                @include breakpoint(mobileonly) {
                    display: none;
                }
            }

            h3 {
                font-weight: 600;
                font-size: 15px;
                margin-bottom: 5px;
            }

            p {
                font-size: 12px;
            }

            .topic-info {
                line-height: 20px;
                .icons {
                    margin-right: 5px;
                    i {
                        padding: 0 3px;
                        background-color: rgba(white, .2);
                        border-radius: 1px;
                        margin-right: 3px;
                    }

                    .icon-lightbulb {
                        background-color: #DAA520;
                        color: rgba(black, .8);
                    }
                }

                p {
                    color: $scheme_color_body_text_darker;
                    a {
                        color: $scheme_color_body_text_darker;
                        text-decoration: underline;
                    }
                }
            }
        }

        .stats {

        }

        .secondary {
            justify-content: space-between;
            align-items: center;

            .last-person {
                width: 150px;

                @include breakpoint(phablet) {
                    display: none;
                }
            }
        }
    }
}