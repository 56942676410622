.modal {
    background-color: black;
    box-shadow: 0 2px 40px 5px rgba(black, .8);

    max-width: 500px;
    width: 95%;

    border-radius: 5px;
    border: 1px solid rgba(white, .15);

    header {
        position: relative;
        padding: 20px;
        background-color: rgba(white, .08);
        h4 {
            margin: 0;
        }

        .close, .custom-close {
            position: absolute;
            top: 0;
            right: 15px;
            line-height: 70px;
            font-size: 1.6em;
        }
    }

    .modal-body {
        padding: 20px;
    }

    footer {
        padding: 10px 20px;
        border-top: 1px solid rgba(white, .15);
        &.is-right {
            text-align: right;
        }
    }
}