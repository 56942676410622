#bbcBox_message {
    display: flex;
    align-items: center;
    line-height: 50px;
    height: 50px;
    padding: 0 5px;

    i {
        cursor: pointer;
        display: inline-block;
        width: 30px;
        height: 50px;
        text-align: center;

        &:hover {
            background-color: rgba(white, .1);
        }
    }

    span.divider {
        display: inline-block;
        margin: 0 10px;
        width: 1px;
        background-color: rgba(white, .2);
        height: 25px;
    }

    select {
        margin: 0;
        font-size: 10px;
    }
}

.zm-editor {
    background-color: #191919;
    border-top: 3px solid $scheme_color_red;
    margin-bottom: 20px;
    box-shadow: $theme_box_shadow;
    border-radius: 3px;

    textarea {
        border: none !important;
        box-sizing: border-box;
        width: 100%;
        background-color: #1f1f1f;
        padding: 20px;

        font-size: 14px;
        line-height: 1.6em;
        letter-spacing: 0.18px;

        color: #fff2f2;
    }

    .editor-main {
        display: flex;
        align-items: center;
        max-width: 100%;
    }

    #smileyBox_message {
        display: inline-block;
        background: url("images/smiley.png") center center no-repeat;
        background-size: 17px;
        opacity: .7;
        width: 17px;
        height: 17px;
        position: relative;
        margin-left: 10px;
        z-index: 100;

        &:hover {
            opacity: 1;
            > div {
                display: flex;
            }
            &:before {
                display: block;
            }
        }
        > div {
            display: none;
            //display: flex;/
            position: absolute;
            top: 150%;
            right: -20px;
            background-color: black;
            box-shadow: $theme_box_shadow;
            border: 1px solid rgba(white, .15);
            padding: 15px;
            border-radius: 3px;

            &:before {
                display: block;
                position: absolute;
                top: -7px;
                z-index: -1;
                right: 21px;
                width: 15px;
                height: 15px;
                content: '';
                background-color: rgba(white, .15);
                box-shadow: $theme_box_shadow;
                transform: rotate(45deg);
            }

            &:after {
                content: '';
                position: absolute;
                top: -17px;
                right: 2px;
                width: 50px;
                height: 21px;
            }

            img {
                position: relative;
                z-index: 1005;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin: 0;
                padding: 0;
                opacity: .6;
                cursor: pointer;

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &:hover {
                    opacity: 1;

                }
            }
        }
    }
}