@font-face {
    font-family: 'fontello';
    src: url('font/fontello.eot?24775605');
    src: url('font/fontello.eot?24775605#iefix') format('embedded-opentype'),
    url('font/fontello.woff2?24775605') format('woff2'),
    url('font/fontello.woff?24775605') format('woff'),
    url('font/fontello.ttf?24775605') format('truetype'),
    url('font/fontello.svg?24775605#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('font/fontello.svg?82278469#fontello') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.icon-chat:before { content: '\e800'; } /* '' */
.icon-user:before { content: '\e801'; } /* '' */
.icon-inbox:before { content: '\e802'; } /* '' */
.icon-bookmark:before { content: '\e803'; } /* '' */
.icon-cog:before { content: '\e804'; } /* '' */
.icon-logout:before { content: '\e805'; } /* '' */
.icon-login:before { content: '\e806'; } /* '' */
.icon-comment:before { content: '\e807'; } /* '' */
.icon-pencil:before { content: '\e808'; } /* '' */
.icon-edit:before { content: '\e809'; } /* '' */
.icon-eye:before { content: '\e80a'; } /* '' */
.icon-eye-off:before { content: '\e80b'; } /* '' */
.icon-pin:before { content: '\e80c'; } /* '' */
.icon-lock:before { content: '\e80d'; } /* '' */
.icon-clock:before { content: '\e80e'; } /* '' */
.icon-chart-line:before { content: '\e80f'; } /* '' */
.icon-bell:before { content: '\e810'; } /* '' */
.icon-link:before { content: '\e811'; } /* '' */
.icon-minus-squared:before { content: '\e812'; } /* '' */
.icon-star:before { content: '\e813'; } /* '' */
.icon-flag-filled:before { content: '\e814'; } /* '' */
.icon-trash-empty:before { content: '\e815'; } /* '' */
.icon-hammer:before { content: '\e816'; } /* '' */
.icon-down-dir:before { content: '\e817'; } /* '' */
.icon-cookie:before { content: '\e818'; } /* '' */
.icon-star-empty:before { content: '\e819'; } /* '' */
.icon-heart:before { content: '\e81a'; } /* '' */
.icon-heart-empty:before { content: '\e81b'; } /* '' */
.icon-thumbs-up:before { content: '\e81c'; } /* '' */
.icon-thumbs-down:before { content: '\e81d'; } /* '' */
.icon-flag:before { content: '\e81e'; } /* '' */
.icon-align-left:before { content: '\e81f'; } /* '' */
.icon-align-center:before { content: '\e820'; } /* '' */
.icon-align-right:before { content: '\e821'; } /* '' */
.icon-align-justify:before { content: '\e822'; } /* '' */
.icon-text-height:before { content: '\e823'; } /* '' */
.icon-italic:before { content: '\e824'; } /* '' */
.icon-bold:before { content: '\e825'; } /* '' */
.icon-font:before { content: '\e826'; } /* '' */
.icon-picture:before { content: '\e827'; } /* '' */
.icon-flow-line:before { content: '\e828'; } /* '' */
.icon-reddit:before { content: '\e829'; } /* '' */
.icon-discord:before { content: '\e82a'; } /* '' */
.icon-left-menu:before { content: '\e82b'; } /* '' */
.icon-rocket:before { content: '\e82c'; } /* '' */
.icon-left-open-mini:before { content: '\e82d'; } /* '' */
.icon-right-open-mini:before { content: '\e82e'; } /* '' */
.icon-up-open-mini:before { content: '\e82f'; } /* '' */
.icon-down-open-big:before { content: '\e830'; } /* '' */
.icon-play:before { content: '\e831'; } /* '' */
.icon-stop:before { content: '\e832'; } /* '' */
.icon-pause:before { content: '\e833'; } /* '' */
.icon-download:before { content: '\e834'; } /* '' */
.icon-upload:before { content: '\e835'; } /* '' */
.icon-mic:before { content: '\e836'; } /* '' */
.icon-dot-3:before { content: '\e837'; } /* '' */
.icon-spin5:before { content: '\e838'; } /* '' */
.icon-dot-2:before { content: '\e839'; } /* '' */
.icon-flow-cascade:before { content: '\e83a'; } /* '' */
.icon-flow-branch:before { content: '\e83b'; } /* '' */
.icon-cup:before { content: '\e83c'; } /* '' */
.icon-trash:before { content: '\e83d'; } /* '' */
.icon-lock-open-1:before { content: '\e83e'; } /* '' */
.icon-lock-1:before { content: '\e83f'; } /* '' */
.icon-firefox:before { content: '\e840'; } /* '' */
.icon-chrome:before { content: '\e841'; } /* '' */
.icon-opera:before { content: '\e842'; } /* '' */
.icon-ie:before { content: '\e843'; } /* '' */
.icon-crown:before { content: '\e844'; } /* '' */
.icon-check:before { content: '\e845'; } /* '' */
.icon-cancel:before { content: '\e846'; } /* '' */
.icon-bookmark-1:before { content: '\e847'; } /* '' */
.icon-bookmarks:before { content: '\e848'; } /* '' */
.icon-chat-1:before { content: '\e849'; } /* '' */
.icon-folder:before { content: '\e84a'; } /* '' */
.icon-archive:before { content: '\e84b'; } /* '' */
.icon-box:before { content: '\e84c'; } /* '' */
.icon-share:before { content: '\e84d'; } /* '' */
.icon-reply-all:before { content: '\e84e'; } /* '' */
.icon-forward:before { content: '\e84f'; } /* '' */
.icon-reply:before { content: '\e850'; } /* '' */
.icon-cancel-circled:before { content: '\e851'; } /* '' */
.icon-tags:before { content: '\e852'; } /* '' */
.icon-tag:before { content: '\e853'; } /* '' */
.icon-download-1:before { content: '\e854'; } /* '' */
.icon-cancel-alt-filled:before { content: '\e855'; } /* '' */
.icon-pin-outline:before { content: '\e856'; } /* '' */
.icon-cog-1:before { content: '\e857'; } /* '' */
.icon-menu-1:before { content: '\e858'; } /* '' */
.icon-mic-outline:before { content: '\e859'; } /* '' */
.icon-mic-1:before { content: '\e85a'; } /* '' */
.icon-chart:before { content: '\e85b'; } /* '' */
.icon-at:before { content: '\e85c'; } /* '' */
.icon-chart-bar:before { content: '\e85d'; } /* '' */
.icon-search:before { content: '\e85e'; } /* '' */
.icon-attention:before { content: '\e85f'; } /* '' */
.icon-error-alt:before { content: '\e860'; } /* '' */
.icon-home:before { content: '\e861'; } /* '' */
.icon-lamp:before { content: '\e862'; } /* '' */
.icon-flash:before { content: '\e863'; } /* '' */
.icon-lightbulb-1:before { content: '\e864'; } /* '' */
.icon-flow-merge:before { content: '\e865'; } /* '' */
.icon-ccw:before { content: '\e866'; } /* '' */
.icon-down-big:before { content: '\e867'; } /* '' */
.icon-left-big:before { content: '\e868'; } /* '' */
.icon-up-big:before { content: '\e869'; } /* '' */
.icon-right-big:before { content: '\e86a'; } /* '' */
.icon-bookmark-empty:before { content: '\f097'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-list-numbered:before { content: '\f0cb'; } /* '' */
.icon-strike:before { content: '\f0cc'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-table:before { content: '\f0ce'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-chat-empty:before { content: '\f0e6'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-exchange:before { content: '\f0ec'; } /* '' */
.icon-bell-alt:before { content: '\f0f3'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-flag-empty:before { content: '\f11d'; } /* '' */
.icon-code:before { content: '\f121'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-attention-alt:before { content: '\f12a'; } /* '' */
.icon-superscript:before { content: '\f12b'; } /* '' */
.icon-subscript:before { content: '\f12c'; } /* '' */
.icon-lock-open:before { content: '\f13e'; } /* '' */
.icon-ellipsis:before { content: '\f141'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-ok-squared:before { content: '\f14a'; } /* '' */
.icon-pencil-squared:before { content: '\f14b'; } /* '' */
.icon-link-ext:before { content: '\f14c'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-yahoo:before { content: '\f19e'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-steam:before { content: '\f1b6'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-paypal:before { content: '\f1ed'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-bell-off:before { content: '\f1f6'; } /* '' */
.icon-eyedropper:before { content: '\f1fb'; } /* '' */
.icon-brush:before { content: '\f1fc'; } /* '' */
.icon-diamond:before { content: '\f219'; } /* '' */
.icon-edge:before { content: '\f282'; } /* '' */
.icon-window-close:before { content: '\f2d3'; } /* '' */