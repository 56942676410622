#zm-login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background: url('images/backgrounds/login-bg-1.jpg') no-repeat center center;
    background-size: cover;

    #zm-login-form {
        display: flex;
        align-items: center;

        max-width: 550px;
        width: 95%;
        background-color: rgba($scheme_color_dark_light, .95);
        box-sizing: border-box;
        padding: 80px;
        border-radius: 3px;
        box-shadow: 0 0 10px rgba(black, .6);

        position: relative;

        .smalltext {
            display: block;
            font-size: 10px;
            color: rgba( white, .5 );
            text-align: center;
        }

        .helper {
            width: inherit;
        }

        header {
            height: 100px;
            line-height: 100px;
            margin-bottom: 40px;

            .logo {
                display: block;
                width: 130px;
                height: 85px;
                background: url('../images/zm/v2/logo.png') no-repeat center center;
                background-size: 130px;
                margin: 0 auto;
            }
        }

        .or {
            position: relative;
            text-align: center;
            color: rgba(white, .6);
            margin: 30px 0;
            display: block;
            &:before, &:after {
                content: '';
                position: absolute;
                display: block;
                height: 1px;
                width: 43%;
                border-top: 1px dashed rgba(white, .2);
                top: 50%;
            }
            &:after {
                right: 0;
            }
        }

        .login, .form {
            h3 {
                font-weight: 100;
                font-size: 18px;
                text-align: center;
                margin-bottom: 40px;
                color: white;
            }

            .title-with-help {
                margin-bottom: 25px;
                h3 {
                    margin-bottom: 10px;
                }
                p {
                    text-align: center;
                    font-size: 12px;
                    color: rgba(white, .7);
                }
            }

            .desc {
                margin-bottom: 20px;
                color: rgba(white, .8);
                background-color: rgba(white, .08);
                padding: 15px;
                border-radius: 3px;
                font-size: 12px;
            }

            input[type='text'], input[type='password'] {
                width: 100%;
                box-sizing: border-box;
                margin-bottom: 10px;

                background-color: rgba(white, .09);
                border: transparent;

                border-radius: 3px;
                padding: 0 20px;

                line-height: 50px;
                height: 50px;
            }

            input[type='text'] {
                border-bottom: 1px solid black;
            }

            .buttons {
                margin: 20px 0 0 0;
            }
        }

        .auth-buttons {
            margin-top: 25px;
            font-size: 23px;
            display: flex;
            justify-content: space-between;

            .btn {
                width: 19%;
                height: 50px;
                line-height: 45px;
                box-sizing: border-box;

                vertical-align: top;
                i {
                    vertical-align: top;
                    padding: 0;
                    margin: 0;
                }
            }

            .google {
                background-color: #4285f4;
            }

            .yahoo {
                background-color: #410093;
            }

            .steam {
                background-color: #00adee;
            }

            .discord {
                background-color: #7289da;
                font-size: 26px;
                i:before {
                    margin-left: 3px;
                }
            }

            .reddit {
                background-color: #ff4500;
                i:before {
                    margin-left: 3px;
                }
            }
        }
    }
}