/* z-slider */
.z-slider {
    box-sizing: border-box;
    color: #d3b126;
    position: relative;
    overflow: hidden;
    * {
        line-height: 1.4;
        list-style: none;
        box-sizing: border-box;
    }

    &:hover {
        .z-slider-nav {
            bottom: 0;
        }
        .z-slider-vid {
            padding: 10px 10px 80px;
        }
    }

    .z-slider-tb {
        transition: width .2s ease;
        overflow: hidden;
        width: 35px;
        svg {
            display: inline-block;
            line-height: 20px;
            color: #000;
            overflow: hidden;
            float: left;
        }
        div {
            display: inline-block;
            position: absolute;
            line-height: 20px;
            color: #000;
            opacity: 0;
            padding-left: 10px;
            white-space: nowrap;
            transition: all .2s;
        }
        opacity: 1;
        transform: scale(1);
        &:hover {
            width: 150px;
            div {
                opacity: 1;
            }
        }

        &.z-slider-pv {
            top: 48px;
        }
        &.z-slider-sd {
            top: 86px;
        }
        &.z-slider-dl {
            top: 124px;
        }
    }
}

.z-slider-wrap {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding: 0;
    margin: 0;
    >div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
    }
    >div.selected {
        position: relative;
        transform: translateX(0);
        transition: transform .5s;
    }
    >div.move-left {
        transform: translateX(-100%);
        transition-delay: 1.2s;
    }
    >div.is-moving {
        transition: transform .5s;
    }
    .z-slider-full-width {
        width: 100%;
        height: 100%;
        text-align: center;
        
        backface-visibility: hidden;
    }
    .z-slider-half-width {
        width: 100%;
        height: 100%;
        text-align: center;
        
        backface-visibility: hidden;
    }
    .z-slider-img-container {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            max-width: 100%;
        }
    }
    .z-slider-bg-video-wrapper {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        video {
            display: block;
            min-height: 100%;
            min-width: 100%;
            max-width: none;
            height: auto;
            width: auto;
            position: absolute;
            left: 50%;
            top: 50%;
            bottom: auto;
            right: auto;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .z-slider-txt-block {
        // Only works on --webkit browsers for now ( safari )
        backdrop-filter: blur( 5px );
        color: #ddd;
        position: absolute;
        left: -1px;
        opacity: 0;
        padding: 6px 20px;
        padding-left: 30px;
        font-size: 1.2rem;
        font-weight: 600;
        text-shadow: 0 1px 3px rgba(0, 0, 0, .1);
        z-index: 10;
        box-shadow: 0 0 5px 0 $scheme_color_red;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, .7);
        transition: all .5s;
        transform: translate(1000px, 0);
        &:nth-of-type(1) {
            top: 5%;
            transition-delay: .2s;
        }
        &:nth-of-type(2) {
            top: 20%;
            transition-delay: .4s;
        }
        &:nth-of-type(3) {
            top: 35%;
            transition-delay: .5s;
        }
        &:nth-of-type(4) {
            top: 50%;
            transition-delay: 0.3s;
        }
    }
    .selected {
        .z-slider-txt-block {
            opacity: 1;
            transform: translate(0);
        }
    }
    .move-left {
        .z-slider-txt-block {
            transform: translate(-1000px, 0);
        }
    }
}
.z-slider-nav {
    backdrop-filter: blur( 5px );
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 2;
    text-align: center;
    background-color: rgba(0, 1, 1, .5);
    transition: all .4s;
    nav {
        position: relative;
    }
    .z-slider-nav-marker {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        color: #d3b126;
        box-shadow: inset 0 2px 0 currentcolor;
        transition: transform .2s, box-shadow .2s;
    }
    ul {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: stretch;
        align-content: stretch;
        width: 100%;
    }
    li {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 100%;
        text-align: center;
        width: 100%;
        flex: 1 1 auto;
        align-self: auto;
        transition: background-color .2s ease, color .2s ease;
        >a {
            display: inline-block;
            position: relative;
            padding: 10px 0;
            font-size: 1rem;
            font-weight: 700;
            color: #d3b126;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &:hover, &.selected  {
            background-color: rgba($scheme_color_primary, .7);
            a {
                color: white;
            }

            &:hover {
                background-color: rgba($scheme_color_primary, .5);
            }
        }
    }
}
.no-js {
    .z-slider-wrap {
        >div {
            display: none;
        }
        >div.selected {
            display: block;
        }
    }
    .z-slider-nav {
        display: none;
    }
}
.z-slider-desc {
    bottom: 0;
    left: 0;
    height: 100%;
    position: absolute;
    background-color: #111;
    width: 100%;
    opacity: 0;
    font-size: 0.8em;
    color: #ddd;
    visibility: hidden;
    padding: 10px 10px 40px;
    text-align: center;
    transition: all .2s;
    transform: scale(0);
    transition-delay: .2s;
    img {
        max-width: 600px;
    }
}
.z-slider-desc.vis {
    opacity: 1;
    overflow: auto;
    overflow-x: hidden;
    visibility: visible;
    z-index: 100;
    transform: scale(1);
}
.z-slider-vid {
    bottom: 0;
    left: 0;
    height: 100%;
    position: absolute;
    background-color: #111;
    width: 100%;
    opacity: 0;
    font-size: 1.6em;
    color: #ddd;
    visibility: hidden;
    padding: 0;
    text-align: center;
    transition: all .2s;
    transform: scale(0);
    transition-delay: .2s;
}
.z-slider-vid.vis {
    opacity: 1;
    overflow: auto;
    overflow-x: hidden;
    visibility: visible;
    z-index: 100;
    transform: scale(1);
}
.z-slider-vid-wrap {
    height: 100%;
    width: 100%;
    overflow: hidden;
    iframe {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
}
.z-slider-vid-close {
    position: absolute;
    cursor: pointer;
    right: 5px;
    top: 5px;
    font-size: .6em;
    background-color: rgba(211, 177, 38, .6);
    text-align: center;
    width: 24px;
    vertical-align: middle;
    font-weight: 700;
}
.z-slider-tb {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(211, 177, 38, .6);
    height: 34px;
    opacity: 0;
    padding: 6px 8px;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transform: scale(0);
    transition: all .2s;
    transition-delay: .1s;
}
@media only screen and (min-width:768px) {
    .z-slider {
        height: 300px;
    }
}
@media only screen and (min-width:1170px) {
    .z-slider {
        height: 400px;
    }
}
@media only screen and (max-width:768px) {
    .z-slider-wrap {
        p {
            left: 0;
        }
    }
}