.zm-dropdown {
    position: relative;
    cursor: pointer;

    &.active {
        &.btn {
            border-radius: 3px 3px 0 0;
            background-color: #b62b36;

            .dropdown {
                border-top: 2px solid #b62b36;
            }
        }
    }

    i {
        padding: 0 5px;
    }

    i, p {
        display: inline-block;
    }

    .dropdown {
        z-index: 100002;
        text-align: left;
        cursor: default;
        display: none;
        position: absolute;
        right: -2px;
        min-width: 150px;
        top: 108%;
        background-color: $scheme_color_hover_elm;
        padding: 15px 0;
        border-radius: 3px 0 3px 3px;
        box-shadow: 0 0px 15px rgba(black, .3);
        
        li {
            list-style: none;
            text-wrap: none;
            white-space: nowrap;
            line-height: 28px;

            a {
                display: block;
                color: rgba( white, .7 );
                text-decoration: none;
                transition: color .2s ease, background .2s ease;
                padding-right: 30px;
                padding-left: 20px;
                &:hover {
                    color: white;
                    cursor: pointer;
                    background-color: rgba(white, .1);
                }
            }
        }

        li.split {
            margin: 7px 0;
            height: 1px;
            width: 100%;
            background-color: rgba(white, .1);
        }
    }
}