footer#zm-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    line-height: 60px;
    background-color: #0f0f0f;
    color: rgba(white, .4);
    font-size: 12px;
    margin-top: 40px;

    .container {
        display: flex;
        justify-content: space-between;
    }

    li {
        display: inline-block;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
}