a.btn {
    color: white !important;
    text-decoration: none !important;
}

.btn {
    cursor: pointer;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 3px;
    border: 2px solid transparent;
    transition: border .2s ease, border-radius .2s ease, background .2s ease;
    background-color: $scheme_color_dark_lighter;

    &:hover {
        border-width: 2px;
        border-color: lighten($scheme_color_red, 10);
    }

    &.is-large {
        line-height: 40px;
        padding: 0 20px;
    }

    &.is-full {
        box-sizing: border-box;
        width: 100%;
    }

    &.is-centered {
        text-align: center;
    }

    &.is-spaced-top {
        margin-top: 10px;
    }

    &.is-spaced-top-large {
        margin-top: 20px;
    }

    &.is-wider {
        padding: 0 3rem;
    }

    &.is-primary {
        color: white;
        background-color: $scheme_color_red_darker;
        &:hover {
            border-color: $scheme_color_red;
        }
    }
}

.button-inline {
    .btn {
        margin-left: 10px;
    }
}

.button-group {
    .btn:not(:last-child) {
        margin-bottom: 10px;
    }

    &.is-spaced-bottom {
        margin-bottom: 30px;
    }
}