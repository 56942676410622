.header-warning {
    line-height: 50px;
    border-bottom: 1px solid rgba($scheme_color_red, .5);
    text-align: center;

    * {
        display: inline-block;
    }

    &.is-danger {
        background-color: $scheme_color_red_darker;
    }

    b {
        font-weight: bold;
        margin-right: 5px;
    }

    i {
        font-size: 20px;
        margin-right: 20px;
    }
}

header#zm-header {
    background: #212121;
    height: 70px;
    position: relative;
    margin-bottom: 10px;

    .container-flex {
        position: relative;
        height: inherit;
        justify-content: space-between;
        width: 95%;
    }

    a {
        text-decoration: none;
    }

    .master {
        line-height: 70px;
        .logo {
            width: 74px;
            height: 70px;
            margin-right: 35px;
            background: url("images/branding/zm-logo-small.png") no-repeat left center;
            background-size: 70px;
        }
        nav {
            @include breakpoint(tablet) {
                display: none;
            
                .is-mobile {
                    display: block;
                }
            }
            
            .is-mobile {
                display: none;
            }
            
            li {
                position: relative;
                list-style: none;
                display: inline-block;
                margin-right: 10px;
                a, i {
                    display: inline-block;
                    color: rgba(white, .7);
                    transition: color .2s ease;
                    padding: 0 10px;
                }

                a {
                    padding-right: 5px;
                }

                i {
                    padding: 0;
                }

                &.selected, &:hover {
                    > a {
                        color: white;
                    }

                    i {
                        color: white;
                    }

                    > ul {
                        visibility: visible;
                        opacity: 1;
                        top: 90%;
                    }
                }
                
                ul {
                    position: absolute;
                    display: block;
                    visibility: hidden;
                    line-height: 2.5em;
                    background-color: $scheme_color_hover_elm;
                    top: 100px;
                    box-shadow: $theme_box_shadow;
                    border-radius: 5px;
                    padding: 10px 0;
                    min-width: 150px;
                    opacity: 0;
                    z-index: 1000;
                    transition: visibility .2s ease, top .2s ease, opacity .2s ease;

                    li {
                        display: block !important;
                        a {
                            white-space: nowrap;
                            padding: 0 15px;
                        }
                    }
                }
            }
        }
    }

    .min-menu {
        line-height: 70px;
        align-items: center;

        .has-split {
            position: relative;
            z-index: 1;
            &::after {
                content: '';
                position: absolute;
                display: block;
                width: 1px;
                height: 20px;
                top: 25px;
                right: 0;
                z-index: -2;
                background-color: rgba(white, .2);
            }
        }

        .social {
            color: $scheme_color_body_text_darker;
            font-size: 1.4rem;
            padding: 0 10px;
            transition: color .2s ease;
            &:not(:last-of-type) {
                padding-right: 0;
            }
            &:hover {
                color: white;
            }

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .profile {
            align-items: center;
            position: relative;
            padding-right: 20px;
            z-index: 50;
            cursor: pointer;
            height: 70px;

            &:hover {
                span.name {
                    color: white;
                }
            }

            &.is-loggedout {
                cursor: default;

                a {
                    color: rgba(white, .7);
                    transition: color .2s ease;
                    &:first-child {
                        margin-right: 20px;
                    }

                    i {
                        margin-right: 5px;
                    }

                    &:hover {
                        color: white;
                    }
                }
            }

            span.name {
                margin-right: 25px;
                color: $scheme_color_body_text_darker;
                transition: color .2s ease;

                @include breakpoint(mobileonly) {
                    display: none;
                }
            }

            > img {
                width: 40px;
                height: 40px;
                border-radius: 40px;
            }

            .user-dropdown {
                cursor: default;
                display: none;
                box-shadow: 0 2px 30px rgba(black, .6);
                position: absolute;
                width: 315px;
                padding-top: 70px;
                padding-bottom: 10px;
                right: -1px;
                top: 0;
                z-index: -1;
                box-sizing: border-box;
                background-color: $scheme_color_hover_elm;
                border-radius: 3px;
                line-height: normal;

                .closer {
                    display: none;
                    text-align: right;
                    font-size: 1.8em;
                    margin-top: 15px;
                    padding-right: 20px;
                    cursor: pointer;

                    @include breakpoint(mobileonly) {
                        display: block;
                    }
                }

                @include breakpoint(phablet) {
                    right: -80px;
                    padding-top: 0 !important;
                    z-index: 1000;
                }

                @include breakpoint(mobileonly) {
                    position: fixed;
                    right: 10px;
                    padding-top: 0 !important;
                    z-index: 1000;
                }

                .logout-nav-option {
                    display: none;
                    @include breakpoint(phablet) {
                        display: block;
                    }
                }

                .logout {
                    position: absolute;
                    top: 25px;
                    left: 20px;
                    z-index: 200;
                    i {
                        font-size: 20px;
                        color: white;
                    }

                    @include breakpoint(phablet) {
                        display: none;
                    }
                }

                .stats {
                    margin-top: 15px;
                    background: url("images/graphics/stats-profile-background.png") bottom center no-repeat;
                    background-size: 100%;
                    padding: 20px 30px;

                    section {
                        .title {
                            font-size: 12px;
                            font-weight: 600;
                            color: rgba(white, .4);
                        }
                        .stat {
                            font-size: 20px;
                            font-weight: 200;
                        }
                    }
                }

                nav.user-nav {
                    margin-top: 25px;
                    section {
                        margin-bottom: 10px;
                        .spacer {
                            height: 1px;
                            background-color: rgba(white, .08);
                            display: block;
                            margin: 5px 20px 10px;
                        }
                        h3 {
                            position: relative;
                            z-index: 100;
                            margin-bottom: 10px;
                            display: block;
                            padding: 0 30px;
                            font-weight: bold;
                            font-size: 14px;
                            color: white;
                        }
                        a {
                            font-weight: 400;
                            display: block;
                            padding: 0 30px;
                            line-height: 35px;
                            text-decoration: none;
                            color: rgba(white, .7);
                            transition: color .2s ease;
                            &:hover {
                                color: white;
                            }

                            i {
                                margin-right: 10px;
                                font-size: 1rem;
                                margin-left: -2px;
                            }
                        }
                    }
                }
            }
        }

        .search {
            cursor: pointer;
            i {
                font-size: 20px;
            }
            margin-left: 15px;
            margin-right: 15px;

            .search-container {
                cursor: default;
                display: none;
                position: absolute;
                height: 70px;
                width: 100%;
                left: 0;
                top: 0;
                z-index: 201;
                box-sizing: border-box;

                input {
                    background-color: black;
                    margin-top: 10px;
                    width: 100%;
                    box-sizing: border-box;
                    display: block;
                    padding-left: 20px;
                    padding-right: 130px;
                    line-height: 100%;
                    height: 50px;
                    outline: none;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    transition: border-color .2s ease;

                    &:hover, &:focus {
                        border-color: $scheme_color_red_darker;
                    }
                }

                .tools {
                    margin-top: 10px;
                    line-height: 50px;
                    position: absolute;
                    right: 0;
                    border-radius: 0 5px 5px 0;
                    top: 0;
                    height: 50px;
                    display: flex;
                    padding: 0 10px;
                    align-items: center;
                    background-color: rgba(white, .08);
                    i {
                        cursor: pointer;
                        font-size: 26px;
                    }
                    .icon-search {
                        font-size: 18px;
                    }

                    button {
                        cursor: pointer;
                        border: 0;
                        background: transparent;
                        color: white;
                        appearance: none;
                    }

                    a {
                        color: white;
                        margin: 0;
                        font-size: 20px;
                        i {
                            font-size: 20px;
                        }

                        margin-left: 5px;
                    }
                }
            }
        }
        
        .more-menu {
            display: none;
            cursor: pointer;
            i {
                font-size: 20px;
            }

            @include breakpoint(tablet) {
                display: block;
            }
            
            .side-nav {
                position: absolute;
                display: none;
                right: -1px;
                top: 85%;
                z-index: 1000;
                background-color: $scheme_color_dark_lighter;
                min-width: 260px;
                box-shadow: $theme_box_shadow;
                border-radius: 5px;
                padding: 10px 0;
                li {
                    line-height: 1.3em;
                    list-style: none;

                    a, i {
                        display: inline-block;
                        color: rgba(white, .7);
                        transition: color .2s ease;
                        padding: 0 10px;
                    }

                    a {
                        padding-right: 5px;
                    }

                    i {
                        padding: 0;
                    }

                    &:hover {
                        > a {
                            color: white;
                        }

                        > i {
                            color: white;
                        }

                        > ul {
                            visibility: visible;
                            opacity: 1;
                            top: 90%;
                        }
                    }

                    > a {
                        line-height: 45px;
                        padding: 0 0 0 25px;
                    }

                    ul {
                        padding: 10px 0px 10px 15px;
                        background-color: rgba(white, .02);

                        a {
                            line-height: 1.8em;
                            padding: 0 10px;
                        }

                        i {
                            font-size: 1em;
                        }
                    }
                }
            }
        }
    }
}