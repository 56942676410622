#poll-area {
    background-color: $scheme_color_dark_light;
    border-radius: 3px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba( black, .5);
    margin-bottom: 20px;

    .title {
        margin-bottom: 5px;
    }

    .poll {
        margin: 15px 0;

        .title {
            font-style: italic;
        }

        .total  {
            margin-top: 10px;
            color: $scheme_color_body_text_darker
        }

        .options {
            .progress {
                margin-top: 10px;

                span {
                    color: $scheme_color_body_text_darker;
                    margin-left: 15px;
                    font-size: 12px;
                    font-style: italic;
                }
                .bar {
                    margin-top: 5px;
                    background-color: $scheme_color_red;
                    display: block;
                    height: 24px;
                    border-radius: 3px;
                }
            }
        }
    }
}

.display-layout {
    header {
        margin: 30px 0;
        .post-info {
            color: rgba(white, .7);
        }

        .pagesection {
            display: flex;
            align-items: center;

            .buttonlist {
                float: none !important;

                ul {
                    margin: 0 2em 0 0;
                    padding: 0;
                }
            }

            .nextlinks {
                a {
                    display: inline-block;
                }
            }
        }
    }

    .display-post {
        position: relative;
        margin-bottom: 30px;
        background-color: $scheme_color_dark_light;
        border-radius: 3px;
        padding: 20px;
        box-shadow: 0 2px 10px rgba( black, .5);

        &.is-first {
            border-top: 2px solid $scheme_color_red;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .post_wrapper {
            display: flex;
            width: 100%;

            .poster {
                position: relative;
                z-index: 1;
                width: 165px;
                min-width: 165px;
                margin-right: 30px;

                text-align: center;

                h2 {
                    position: relative;
                    z-index: 3;
                }

                .profile-avatar {
                    z-index: 2;
                    position: relative;
                    width: 75px;
                    height: 75px;
                    margin: 20px auto;
                    border-radius: 75px;
                    background-color: rgba(black, .3);
                    border: 1px solid rgba(black, .3);
                    .online-indicator {
                        width: 15px;
                        height: 15px;
                        box-shadow: 0 1px 20px rgba(black, .7);
                        display: block;
                        border-radius: 15px;
                        position: absolute;
                        left: 3px;
                        top: 0;
                        z-index: 10;

                        &.is-online {
                            background-color: #20c517;
                        }

                        &.is-offline {
                            background-color: $scheme_color_red;
                        }
                    }

                    .avatar {
                        width: 75px;
                        height: 75px;
                        border-radius: 75px;
                    }

                    > a > img {
                        width: 75px;
                        height: 75px;
                        border-radius: 75px;
                    }
                    
                    &:hover {
                        .profile-hover-about {
                            display: flex;
                        }
                    }

                    .profile-hover-about {
                        top: -50px;
                        left: -45px;
                        //width: 165px;
                        height: 155px;
                        position: absolute;
                        background-color: $scheme_color_hover_elm;
                        box-shadow: 0 1px 20px rgba(black, .5);
                        border-radius: 3px;
                        z-index: -1;
                        display: none;

                        .spacer {
                            width: 165px;
                            min-width: 165px;
                            background-color: rgba( white, .04 );
                        }

                        .main {
                            flex-grow: 1;
                            text-align: left;
                            nav {
                                display: flex;
                                text-align: center;
                                background-color: rgba(black, .07);
                                border-radius: 0 3px 0 0;
                                padding: 0 40px;
                                line-height: 40px;
                                a {
                                    white-space: nowrap;
                                    font-size: 13px;
                                    padding: 0 20px;
                                    color: $scheme_color_body_text_darker;
                                    transition: color .2s ease;
                                    &:hover {
                                        color: white;
                                    }
                                }
                            }

                            .profile-info {
                                position: relative;
                                padding: 20px;
                                text-align: left;
                                .item {
                                    display: inline-block;
                                    vertical-align: top;
                                    &:first-child {
                                        display: block;
                                        margin-bottom: 12px;
                                    }
                                    .title {
                                        font-size: 12px;
                                        margin-bottom: 5px;
                                        color: $scheme_color_body_text;
                                    }
                                    .text {
                                        color: white;
                                    }
                                }

                                .stars {
                                    position: absolute;
                                    bottom: 10px;
                                    right: 10px;
                                }
                            }
                        }
                    }
                }

                li {
                    line-height: 20px;
                }

                .profile-stats {
                    line-height: 30px;
                    text-align: center;
                    margin: 10px auto 10px auto;

                    & > div {
                        width: 50%;
                        background-color: $scheme_color_dark_lighter;

                        &:first-child {
                            background-color: lighten($scheme_color_dark_lighter, 3);
                        }

                        i {
                            font-size: 16px;
                            margin-right: 4px;
                        }
                    }

                }

                .stars {
                    margin-top: 10px;
                }
            }

            .postarea {
                flex: 1;
                .post-about {
                    line-height: 25px;
                    .keyinfo {
                        color: $scheme_color_body_text_darker;
                    }

                    .zm-dropdown {
                        background-color: $scheme_color_dark_lighter;
                        margin-right: 5px;
                        border-radius: 3px;
                        transition: background-color .2s ease;

                        &:hover {
                            background-color: $scheme_color_red_darker;
                        }

                    }

                    .inline_mod_check {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }

                .post {
                    margin-top: 10px;
                    display: block;
                    max-width: 100%;

                    line-height: 1.6em;
                    letter-spacing: 0.18px;

                    color: #fff2f2;

                    .codeheader {
                        line-height: 40px;
                        display: block;
                        background-color: rgba(black, .3);
                        padding: 0 20px;
                        border-radius: 3px 3px 0 0;
                        margin-top: 10px;
                    }

                    code.bbc_code {
                        display: block;
                        max-width: 100%;
                        width: 100%;
                        box-sizing: border-box;

                        padding: 20px;
                        border-radius: 0 0 3px 3px;
                        margin-bottom: 10px;
                        background: black;
                    }
                }

                .moderatorbar {
                    margin-top: 15px;
                    opacity: 0.4;
                    will-change: opacity;
                    transition: opacity .3s ease;

                    &:hover {
                        opacity: .9;
                    }
                }
            }
        }
    }
}

#quickreplybox {
    margin-top: 20px;
}