.spoiler-container {
    display: block;
    margin: 8px 0;
    .spoiler-head {
        text-align: center;
        line-height: 35px;
        border-radius: 3px;
        color: $scheme_color_body_text;
        background-color: $scheme_color_dark_lighter;
        text-transform: uppercase;
        cursor: pointer;
        transition: color .2s ease, background .2s ease;
        &:hover {
            color: white;
            background-color: lighten($scheme_color_dark_lighter, 5);
        }
        &.active {
            border-radius: 3px 3px 0 0;
            background-color: lighten($scheme_color_dark_lighter, 5);
            &:hover {
                background-color: lighten($scheme_color_dark_lighter, 2);
            }
        }
    }

    .spoiler-body {
        background-color: $scheme_color_dark_lighter;
        padding: 15px;
        border-radius: 0 0 3px 3px;
    }
}