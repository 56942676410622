.content {
    dl {
        dt {
            color: rgba(white, .7);
        }
        dt, dd {
            line-height: 1.3em;
        }
    }
}

.quoteheader
{
    color: #666;
    font-weight: bold;
    line-height: 2em;
    font-size: .8em;
}

/* A quote, perhaps from another post. */
blockquote.bbc_standard_quote, blockquote.bbc_alternate_quote
{
    color: #f2f2f2;
    overflow: auto;
    border-left: 2px solid rgba(white, .5);
    padding: 10px 3px 10px 15px;
    max-width: 100%;
}

/* Alterate blockquote stylings */
blockquote.bbc_standard_quote
{
    background-color: $scheme_color_dark_lighter;
}

blockquote.bbc_alternate_quote
{
    background-color: lighten($scheme_color_dark_lighter, 2);
}

// TODO: Move to modal class
#quick-mod-modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(black, .7);
    backdrop-filter: blur(3px);

    &.active {
        display: flex;
    }
}