h1.title, h2.title, h3.title, h4.title, h5.title, h6.title {
    color: white;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    margin-bottom: 20px;

    &.is-1 {
        font-size: 3rem;
    }

    &.is-2 {
        font-size: 2.5rem;
    }

    &.is-3 {
        font-size: 2rem;
    }

    &.is-4 {
        font-size: 1.5rem;
    }

    &.is-5 {
        font-size: 1.3rem;
    }

    &.is-6 {
        font-size: 1.1rem;
    }

    &.no-margin {
        margin-bottom: 0;
    }

    &.is-light {
        font-weight: 100;
    }
}