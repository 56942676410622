#donate-app, #donate-lazy {
    padding-top: 40px;

    .main {
        margin-bottom: 60px;

        > p {
            color: $scheme_color_body_text;
            letter-spacing: 0.1px;
            line-height: 1.2em;
            text-align: justify;
            margin-bottom: 30px;
        }

        .donation-box {
            #donation-app {
                margin-bottom: 20px;
                .item {
                    line-height: 40px;
                    height: 40px;
                    padding: 0 40px;
                    background-color: rgba($scheme_color_red, .5);
                    border: 1px solid $scheme_color_red;
                    border-right: none;
                    cursor: pointer;
                    transition: background .2s ease;

                    &.active, &:hover {
                        background-color: $scheme_color_red;
                    }

                    &:first-child {
                        border-radius: 3px 0 0 3px;
                    }

                    &:last-child {
                        border-radius: 0 3px 3px 0;
                        border-right: 1px solid $scheme_color_red;
                    }
                }
            }
        }
    }

    .donators {
        .donator-list {
            background-color: black;
            padding: 30px;
            border-radius: 3px;
            box-shadow: $theme_box_shadow;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: -15px;
                right: -10px;
                width: 45px;
                height: 50px;
                background: url("images/graphics/heart-thing.png") no-repeat center center;
                box-shadow: 0 0 20px black;
            }

            .list-index {
                width: 100%;
                flex-wrap: wrap;

                .item {
                    width: 47%;
                    padding: 10px 0 ;
                    .username {
                        font-size: 1rem;
                        margin-bottom: 3px;
                        display: block;
                    }

                    .date {
                        font-size: 12px;
                        color: $scheme_color_body_text_darker;
                    }

                    .amount {
                        font-weight: 100;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    .button-inline {
        margin-top: 20px;
        .btn {
            display: inline-block;
        }
    }
}