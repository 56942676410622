.downloads.list-index {
    background: $scheme_color_dark_light;
    padding: 30px;
    border-radius: 3px;
    box-shadow: $theme_box_shadow;
    width: 100%;
    box-sizing: border-box;

    .downloads-cats {
        background-color: $scheme_color_dark_lighter;
        margin-bottom: 30px;
        text-align: center;
    }

    .item {
        &:not(:last-child) {
            margin-bottom: 15px;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(white, .05);
        }
        .about {
            .title {
                display: block;
                font-size: 1.2em;
                margin-bottom: 5px;
            }
            .context {
                color: rgba($scheme_color_body_text_darker, .8);
                font-size: 12px;
            }
        }

        .meta {
            .stats-board-layout {
                margin-right: 0;
                .stat {
                    width: 80px;
                }
            }
        }
    }
}

#download-home {
    .main {
        width: 70%;
        margin-right: 3%;

        .heading {
            height: 80px;
            .drops {
                line-height: normal;
                .zm-dropdown {
                    p {
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    .popular {
        flex-grow: 1;
        .items {
            background-color: black;
            border-radius: 5px;
            padding: 20px;
            box-shadow: $theme_box_shadow;
            margin-bottom: 20px;
            border: 1px solid rgba(white, .13);

            .item {
                width: 100%;
                align-items: center;
                &:not(:last-child) {
                    margin-bottom: 15px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid rgba(white, .1);
                }

                .info {
                    margin-top: 3px;
                    opacity: .7;
                    font-size: 12px;
                }

                .value {
                    font-size: 1.3rem;
                    font-weight: 100;
                }
            }
        }
    }
}

.download-add {
    .form {
        display: flex;
        flex-wrap: wrap;
        .download-form-main {
            width: 47%;
            margin-right: 3%;
        }

        .download-form-second {
            width: 47%;
            margin-left: 3%;
        }

        .download-form-desc {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}

.download-main {
    background: $scheme_color_dark_light;
    padding: 20px;
    border-radius: 3px;
    box-shadow: $theme_box_shadow;

    .meta-info {
        background: black;
        padding: 30px;
        border-radius: 3px;
        box-shadow: $theme_box_shadow;

        .main {
            .title {
                margin-bottom: 3px;
            }
            .meta-about {
                > div {
                    display: inline-block;
                    margin-right: 10px;
                    font-size: 12px;

                    span {
                        color: $scheme_color_body_text_darker;
                    }
                }
            }
        }
        .rating {
           .circle-rate {
               form:not(.voted) {
                   i {
                       cursor: pointer;
                   }
               }
                align-items: center;
                .icon {
                    font-size: 1.3rem;
                    &:before {
                        margin: 3px;
                    }
                    &:not(.tmp) {
                        &.icon-star, &.icon-star-half-alt {
                            color: #E5E140;
                        }
                    }
                }
            }
        }
    }

    .get-content {
        box-sizing: border-box;

        .desc {
            padding: 30px 20px;
            width: 71%;
            margin-right: 1%;

            .preview {
                margin-bottom: 15px;
            }

            line-height: 1.4em;

            ul, li {
                list-style: unset;
            }

            ul {
                padding: 1em 1em 1em 1.5em;
            }
        }

        .download {
            width: 27%;
            padding-top: 30px;
            box-sizing: border-box;
            padding-right: 20px;
        }

        .meta-data {
            margin-top: 30px;
            .item {
                margin-bottom: 25px;
                font-size: 1.2rem;
                span {
                    margin-bottom: 8px;
                    display: block;
                    color: $scheme_color_body_text_darker;
                    font-size: 12px;
                }

                &.keywords {
                    font-size: 14px;
                    .flex {
                        flex-wrap: wrap;
                    }
                    .tag {
                        font-size: 13px;
                        color: rgba(white, .7);
                        border: 1px solid $scheme_color_red;
                        border-radius: 3px;
                        line-height: 25px;
                        padding: 0 10px;
                        background-color: $scheme_color_red_darker;
                        margin-right: 5px;
                    }
                }
            }

            .item.tools {
                font-size: 14px;
                margin-top: -5px;
                .btn {
                    display: inline-block;
                }
            }
        }

        .next-btns {
            .btn {
                &:first-child {
                    margin-right: 5px;
                }
            }
        }
    }
}

.download-comments {
    margin-top: 30px;

    .comment-index {
        .comment-item {
            background-color: $scheme_color_dark_light;
            border-radius: 3px;
            box-shadow: $theme_box_shadow;
            padding: 20px;
            margin-bottom: 20px;

            .member {
                margin-bottom: 15px;
                padding-bottom: 13px;
                border-bottom: 1px solid rgba(white, .05);
                align-items: center;
                .about {
                    .name {
                        font-size: 1.1rem;
                    }
                    .group {
                        margin-left: 5px;
                        font-size: 12px;
                        color: rgba(white, .7);
                    }
                }
                .date {
                    color: $scheme_color_body_text_darker;
                }
            }
        }
    }
}

.downloads-pages {
    margin-top: 0;
    margin-bottom: 20px;
}

.downloads-search {
    h5 {
        margin-bottom: 0;
    }
    section {
        padding: 10px 0;
        color: $scheme_color_body_text;

        > label {
            margin-bottom: 5px;
            display: block;
        }

        .input {
            margin-bottom: 10px;
        }

        .options {
            margin-top: 10px;
            label {
                display: block;
            }
        }
    }

    .button-inline {
        .btn:first-child {
            margin-left: 0 !important;
        }
    }
}

.download-index {
    .downloads-cat-header {
        padding: 20px 0;
        h4 {
            margin-bottom: 0;
        }
        .btn {
            display: block;
        }
    }
    .categories {
        background-color: $scheme_color_dark_light;
        border-radius: 3px;
        box-shadow: $theme_box_shadow;
        padding: 30px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;

        .download-cat {
            margin-top: 20px;

            &:first-child:not(:last-child) {
                margin-top: 0;
            }

            .about {
                .name {
                    display: block;
                    font-size: 1rem;
                    margin-bottom: 3px;
                }
                .desc {
                    color: $scheme_color_body_text_darker;
                }
            }
        }
    }

    .download-items {

    }
}

#downloads-myfiles {
    .item {
        padding: 20px;
        border-radius: 5px;
        transition: background-color .2s ease, box-shadow .2s ease;
        &:hover {
            background-color: rgba(white, .1);
            box-shadow: 0 2px 30px 10px rgba(black, .2);
        }
        &:not(:last-child) {
            border-bottom: 1px solid rgba(white, .05);
        }

        a.section {
            &:hover {
                text-decoration: none;
                .body {
                    text-decoration: underline;
                }
            }
        }

        .section {
            width: 15%;
            &:first-child {
                width: 40%;
            }

            .heading {
                font-weight: 400;
                color: rgba(white, .6);
                font-size: 12px;
                margin-bottom: 10px;
            }

            .body {
                font-size: 16px;
                font-weight: 100;
            }
        }

        .meta {
            width: 2%;
            text-align: right;
            .is-full {
                display: inline-block;
                width: auto;
            }
        }
    }
}

#downloads-ratings {
    width: 100%;
}