.form-group {
    margin-bottom: 20px;
    &.inline {
        display: flex;
        & > * {
            width: 50%;
            &:not(:nth-child(2)) {
                margin-right: 15px;
            }
            &:not(:nth-child(1)) {
                margin-left: 15px;
            }
        }
    }
}

.form {
    .group {
        margin-bottom: 15px;
        &.is-bold {
            label:not(.checkbox) {
                font-size: 18px;
                font-weight: 100;
            }
        }

        label {
            display: block;
            margin-bottom: 8px;
            font-size: 15px;
        }

        p {
            color: $scheme_color_body_text_darker;
            margin-bottom: 12px;
            font-size: 13px;
        }
    }
}