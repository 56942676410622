.breadcrumb {
    padding: 15px 0;
    margin-bottom: 10px;
    font-size: 12px;


    ul {
        display: block;
        li {
            display: inline-block;

            span {
                margin: 0 7px;
                color: rgba(white, .4);
            }
        }
    }
}