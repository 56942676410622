.dropmenu {
    display: block;
    line-height: 40px;
    height: 40px;
    list-style: none;
    padding: 0 15px;
    margin: 0;
    background-color: $scheme_color_dark_lighter;
    border-radius: 3px;
    li {
        list-style: none;
    }

    > li {
        display: inline-block;
        padding: 0 10px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    li {
        position: relative;
        cursor: pointer;
        &:hover {
            i {
                color: $scheme_color_primary;
            }
            > ul {
                display: block;
            }
        }
        ul {
            z-index: 10000;
            display: none;
            position: absolute;
            top: 90%;
            left: -5px;
            line-height: normal;
            background-color: black;
            padding: 7px 0px;
            box-shadow: 0 2px 15px rgba(black, .7);
            border: 1px solid rgba(white, .15);
            border-radius: 5px;
            cursor: default;
            li {
                position: relative;
                white-space: nowrap;
                line-height: 1.5;
                padding: 0.375rem 1rem;
                padding-right: 3rem;

                &:hover {
                    > a {
                        color: #ffffff;
                    }
                }
                a {
                    color: #b1b1b1;
                }

                ul {
                    position: absolute;
                    left: 90%;
                    top: 0;
                }
            }
        }
    }
}

.adm_section {
    .left_admmenu {
        li {
            line-height: 1.5em;
        }
    }
}