#zm-search-container {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    background-color: rgba(black, .8);
    backdrop-filter: blur(5px);

    width: 100%;
    height: 100%;
    z-index: 2000;
    display: none;
    justify-content: center;
    align-items: center;

    #zm-search-master {
        width: 98%;
        max-width: 550px;

        header {
            text-align: center;
            h2 {
                margin-bottom: 10px;
            }
            p {
                color: $scheme_color_body_text;
            }
        }

        .search-body {
            position: relative;
            margin: 30px 0 35px 0;
            .search-input {
                position: relative;
                height: 70px;
                line-height: 70px;
                width: 100%;

                font-size: 16px;
                padding: 0 70px 0 20px;
                box-sizing: border-box;
                border: 0;
                background-color: rgb(232, 236, 233);
                color: rgba(black, .7);
                border-radius: 8px;
                box-shadow: 0 2px 30px 10px rgba(black, .8);
                appearance: none;
                font-family: "Lato", sans-serif;
                outline: none;
                cursor: text;
            }

            button {
                position: absolute;
                top: 5px;
                right: 5px;
                height: 60px;
                width: 40px;
                line-height: 60px;
                background: rgb(210, 214, 211);
                border: none;
                -moz-appearance: none;
                -webkit-appearance: none;


                i {
                    height: 60px;
                    width: 40px;
                    display: block;
                    text-align: center;
                    line-height: 60px;
                    color: #595959;
                    font-size: 16px;

                    border-radius: 8px;
                    cursor: pointer;
                    transition: color .2s ease, background .2s ease;

                    &:hover {
                        background: $scheme_color_red;
                        color: white;
                    }
                }
            }
        }

        .where-options {
            margin: 0 30px;
            padding: 10px;
            background-color: $scheme_color_dark_light;
            border-radius: 8px;

            .item {
                width: 25%;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                color: rgba(white, .5);
                transition: color .2s ease, background .2s ease;
                cursor: pointer;

                &:not(.disabled):not(.active):hover {
                    background: $scheme_color_red_darker;
                    color: white;
                }

                &.active {
                    background-color: $scheme_color_red;
                    color: white;
                }

                &.disabled {
                    text-decoration: line-through;
                    color: rgba(white, .2);
                    cursor: not-allowed;
                }
            }
        }

        .advanced {
            color: rgba(white, .2);
            text-align: center;
            display: block;
            margin-top: 20px;
            transition: color .2s ease;

            &:hover {
                color: white;
            }
        }
    }
}