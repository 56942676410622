#registration {
    &.step1 {
        p {
            text-align: justify;
            display: block;
            max-height: 240px;
            overflow: auto;
        }
    }

    .g-recaptcha {
        > div {
            width: 100% !important;
            iframe {
                margin: 0 auto;
                display: block;
            }
        }
    }

    input[type="text"], input[type="password"], input[type="email"] {
        height: 40px !important;
        line-height: 40px !important;
    }
}

.register {

}