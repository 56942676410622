.clog {
    .icon {
        display: inline-block;
        width: 30px;
        min-width: 30px;
        height: 30px;
        text-align: center;
        line-height: 28px;
        border-radius: 30px;
        font-weight: 400;
        &.added {
            background-color: #22E250;
        }

        &.removed {
            background-color: #22E2A7;
        }

        &.changed {
            background-color: #2264E2;
        }

        &.fixed {
            background-color: #E22275;
        }

        &.security {
            background-color: #E22222;
        }
    }

    .change {
        background-color: $scheme_color_dark_lighter;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 15px;

        .icon {
            margin-right: 15px;
            width: 25px;
            height: 25px;
            min-width: 25px;
            line-height: 23px;
        }

        .body {
            color: $scheme_color_body_text;
            letter-spacing: .3px;
        }
    }
}

#changelog {
    > header {
        margin-bottom: 55px;
        .meta {
            justify-content: center;
            .item {
                &:not(:last-child) {
                    margin-right: 25px;
                }

                span {
                    margin-left: 5px;
                    color: $scheme_color_body_text;
                }
            }
        }
    }

    .change-list {
        .item {
            header {
                .heading {
                    h4 {
                        line-height: 40px;
                        span {
                            font-weight: 100;
                            margin-right: 10px;
                        }
                    }
                    .version-tags {
                        .tag {
                            font-size: 13px;
                            margin-right: 5px;
                            padding: 5px 10px;
                            border-radius: 3px;
                            background-color: $scheme_color_red;
                            line-height: normal;
                            &.type {
                                margin-right: 15px;
                            }
                        }

                        .date {
                            font-style: italic;
                        }
                    }
                }
                .desc {
                    text-align: justify;
                }
            }
        }
    }
}

#changelog-form {
    .main {
        width: 73%;

        .helper {
            margin-top: 10px;
            font-size: 12px;
            color: $scheme_color_body_text_darker;
        }
    }
    .secondary {
        width: 27%;
        background-color: black;
        padding: 25px;
        box-sizing: border-box;
        border-radius: 3px;
        box-shadow: 0 2px 40px 10px rgba(black, .5);
        .setting {
            .title {
                margin-bottom: 5px !important;
                font-style: italic;
            }
            label {
                display: block;
                line-height: 30px;
                color: $scheme_color_body_text;
                input {
                    margin: 0;
                    margin-right: 10px;
                }
            }
        }
    }
}

#changelog-cform {
    .type {
        width: 20%;
        .input {
            max-width: 100%;
            min-width: 100% !important;

            option {
                text-transform: capitalize;
            }
        }
    }

    .desc {
        width: 78%;
        .input {
            width: 100%;
        }
    }
}

#changelog-add-changes {
    header {
        .item {
            margin-right: 10px;
            background-color: $scheme_color_dark_lighter;
            line-height: 25px;
            padding: 0 10px;
            border-radius: 3px;
            font-size: 12px;
            span {
                font-weight: 100;
                font-style: italic;
                color: rgba($scheme_color_body_text_darker, .8);
                margin-right: 5px;
            }
        }
    }
}