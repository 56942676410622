#quick-mod-container {
    display: none;
    position: fixed;
    z-index: 100;
    height: 0;
    bottom: 20px;
    left: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    
    &.active {
        display: flex;
    }
    
    #quick-mod-tools {
        display: flex;
        margin-bottom: 100px;
        padding: 10px;
        .mod-item {
            font-size: 1.6em;
            padding: 10px;
            cursor: pointer;
            transition: background .2s ease;

            &:hover {
                background-color: rgba(white, .1);
                border-radius: 3px;
            }
        }
    }
}