#bookmarks {
    background-color: $scheme_color_dark_light;
    padding: 30px;

    .header {
        margin-bottom: 20px;
    }

    .list-index {
        margin: 40px 0;
        .item {

            &:not(:last-child) {
                margin-bottom: 15px;
                padding-bottom: 10px;
                border-bottom: 1px solid rgba(white, .05);
            }

            .main {
                .title {
                    font-size: 0.9rem;
                    margin-bottom: 5px;
                }
            }

            .meta {
                color: $scheme_color_body_text_darker;
                a {
                    color: $scheme_color_body_text;
                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }

    .button-inline {
        margin-top: 20px;
        input {
            margin-left: 0;
        }
    }
}