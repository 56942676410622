a,
a:link,
a:active,
a:visited,
a:hover {
  color:           #D3B126;
  text-decoration: none;
}

a:hover {
  color: #E9C537;
  text-decoration: underline;
}

@import "vars";
@import "inputs";
@import "forms";
@import "typography";
@import "buttons";
@import "modal";